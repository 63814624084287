import React from "react";
import SuccessPageAnimation from "../successPageAnimation";
import wlogo  from "../../images/wlogo.svg"
import SuccessPageLeftGraphic  from "../../images/success-page-left.svg"
import SuccessPageRightGraphic  from "../../images/success-page-right.svg"

import styles from "./styles.module.css";
import { Link } from "gatsby";

const InquiryThankYouPage = ({location}) => {
    const email = location && location.state && location.state.email;
    return (
        <div className={[styles.container].join(' ')}>
            <Link className={styles.mprofitLogo} to="/"><img src={wlogo} alt="MProfit Logo" /></Link>
            <img src={SuccessPageLeftGraphic} className={styles.successPageLeftGraphic}/>
            <img src={SuccessPageRightGraphic} className={styles.successPageRightGraphic}/>
            <SuccessPageAnimation/>
            <div className={styles.loginInstructions}>Thank you for your interest in MProfit</div>
            {/* <div className={styles.thankYou}>Thank you for expressing interest in learning more about MProfit</div> */}
            <div className={styles.thankYou}>We'll contact you as soon as possible!</div>
        </div>
    );
}


export default InquiryThankYouPage